import React from 'react'

export const formatYmal = (ymal, ymalImages) => {
	ymal.forEach(product => {
		product.description =
			typeof product.description === 'string'
				? product.description.split(/\r?\n/)
				: product.description
		product.image = ymalImages.find(
			img =>
				((img.parent?.name.includes('collection') || img.parent?.name.startsWith('_')) &&
					img.parent?.name.endsWith(product.asset_filename)) ??
				img.fluid.originalName.endsWith(`${product.asset_filename}.png`)
		)
	})
	return ymal
}

export const formatProducts = (products, images, ymal) => {
	products.map(product => {
		product.description =
			typeof product.description === 'string'
				? product.description.split(/\r?\n/)
				: product.description
		product.image = images.find(
			image =>
				image.parent.name.startsWith(`collection${product.collection_id}`) &&
				image.parent.name.endsWith(product.asset_filename)
		)
		if (typeof product.YMAL === 'string') {
			product.YMAL = product.YMAL.split(',')
			product.YMAL = product.YMAL.map(prod => prod.split('p').pop())
		}
		product.ymalProducts = []
		if (ymal) {
			ymal.forEach(p => {
				if (product.YMAL.includes(p.product_id)) {
					product.ymalProducts.push(p)
				}
			})
		}
	})
	return products
}

export const formatCollections = (
	collections,
	collectionIds,
	images,
	buttonConfig,
	shelf
) =>
	collections.map((col, i) => ({
		name: col,
		buttonConfig: {
			...buttonConfig,
			analytics: {
				product: `${col} - Shelf #${shelf}`,
				button: 'Open Collection'
			},
			url: collectionIds[i]
		},
		image: images.find(image =>
			image.fluid.originalName.includes(`collection${collectionIds[i]}`)
		)
	}))

export const stringToArray = string => string.split(',')

export const orderYmal = (order, ymal) => {
	const ordered = []

	order.forEach((name, i) => {
		ordered[i] = ymal.find(p => p.image.fluid.originalName.includes(name))
	})

	return ordered
}

export const formatAttractPage = data => {
	const inlineImages = data.allProductImages.nodes.filter(image =>
		image.fluid.originalName.includes('collection')
	)
	const menuData = { endcap: {}, inline: {} }

	data.allProductsCsv.nodes.forEach(prod => {
		if (prod.collection_id) {
			getProductImage(prod, inlineImages, 'collection')
			pushProductData(menuData.inline, prod)
		}
	})
	return menuData
}

const getProductImage = (prod, imagesArray, type) =>
	(prod.image = imagesArray.find(image =>
		image.fluid.originalName.includes(
			`${type}${prod.collection_id}_${prod.asset_filename}`
		)
	))

const pushProductData = (key, prod) => {
	key[prod.collection_id]
		? key[prod.collection_id].prods.push(prod)
		: (key[prod.collection_id] = {
				id: +prod.collection_id,
				prods: [prod]
		  })
}

export const convertRegisterdSymbol = (text) => {
	if (typeof(text) !== 'string') {
		return text;
	}
	if (!text.includes('®')) {
		return React.createElement('text', null, text);
	}
	const index = text.indexOf('®');
	const firstPart = text.slice(0, index);
	const secondPart = text.slice(index + 1);
	const symbol = React.createElement('sup', {className: 'registered-symbol', style: {'font-size':'60%'}}, '®');
	return React.createElement('span', null, firstPart, symbol, secondPart);
}
