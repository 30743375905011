import { useState, useEffect } from 'react'

export const useEnv = (name, defaultVal) => {
	const [value, setValue] = useState(defaultVal)
	useEffect(() => {
		const isBrowser = typeof window !== 'undefined'
		if (isBrowser) {
			setValue(() => window.configs[name] || defaultVal)
		}
	}, [setValue])
	return value
}
