// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-collection-collection-js": () => import("./../../../src/templates/collection/collection.js" /* webpackChunkName: "component---src-templates-collection-collection-js" */),
  "component---src-templates-shelf-shelf-js": () => import("./../../../src/templates/shelf/shelf.js" /* webpackChunkName: "component---src-templates-shelf-shelf-js" */)
}

