export const collectionNames = [
	'Cleansers',
	'Facial Moisturizers',
	'Primer Serums',
	'Serums and Moisturizers',
	'Treatments',
	'Makeup Removing Cleansers',
	'Cleansing Treatments',
	'Makeup Remover Wipes',
	'Anti-Aging',
	'Anti-Aging',
	'Anti-Aging',
	'Daily Cleansers',
	'Gentle Cleansers',
	'Facial Moisturizers',
	'Toners and Makeup Removers'
]
