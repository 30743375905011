import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const ADAContext = createContext({ ADA: false, setADA: () => {} })

export const ADAProvider = ({ children }) => {
	const [ADA, setADA] = useState(false)

	return (
		<ADAContext.Provider value={{ ADA, setADA }}>
			{children}
		</ADAContext.Provider>
	)
}

ADAContext.propTypes = {
	children: PropTypes.node.isRequired
}
