import { graphql, useStaticQuery } from 'gatsby'

const getCollections = () => {
	const { pages } = useStaticQuery(
		graphql`
			{
				pages: allSitePage(filter: { path: { glob: "/collection*" } }) {
					distinct(field: path)
				}
			}
		`
	)

	return pages.distinct
}

export default getCollections
