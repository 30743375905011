import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import { Detector } from 'react-detect-offline'

export const OnlineContext = createContext({ hasInternet: true })

export const OnlineProvider = ({ children }) => {

	return (
		<Detector
			polling={{
				interval: 30000,
				timeout: 10000
			}}
			render={({ online }) => (
				<OnlineContext.Provider value={{ hasInternet: online }}>
					{children}
				</OnlineContext.Provider>
			)}
		/>
	)
}

OnlineContext.propTypes = {
	children: PropTypes.node.isRequired
}
