import WebSocket from 'isomorphic-ws'
export class PerchHubConnection {
	constructor() {
		this.websocketOpen = false

		this.listeners = {
			sensing: []
		}
		// this.setupSocket(this)
	}

	on(type, cb) {
		if (type === 'sensing') {
			this.listeners.sensing.push(cb)
		}
	}

	setupSocket = (hubUrl) => {
		try {
			this.socketRetryTime = 60 // seconds until retrying the socket
			// Set up analytics socket
			if (hubUrl === 'none') {
				return
			}
			this.hubSocket = new WebSocket(hubUrl)
			this.hubSocket.onopen = () => {
				console.info('Perch unit websocket connected.')

				// Set up sensing socket
				this.hubSocket.onmessage = this.onSensingMessage.bind(this)

				// eslint-disable-next-line no-unused-vars
				this.hubSocket.onclose = e => {
					console.info(
						'Hub socket closed unexpectedly, retrying in ' +
							this.socketRetryTime +
							' seconds...'
					)
					setTimeout(() => {
						this.setupSocket(this)
					}, this.socketRetryTime * 1000)
				}

				this.websocketOpen = true
			}

			// Handle Errors
			this.hubSocket.onerror = e => {
				console.info(
					'Hub socket error: ',
					e,
					'. Retrying in ' + this.socketRetryTime + ' seconds...'
				)
				this.hubSocket.close()
				this.hubSocket = null
				setTimeout(() => {
					this.setupSocket(this)
				}, this.socketRetryTime * 1000) // retry
			}
		} catch (e) {
			// All other errors
			console.info(
				'Hub socket error: ',
				e,
				'. Retrying in ' + this.socketRetryTime + ' seconds...'
			)
			if (this.hubSocket) {
				this.hubSocket.close()
				this.hubSocket = null
			}
			setTimeout(() => {
				this.setupSocket(this)
			}, this.socketRetryTime * 1000) // retry
		}
	}

	removeAllEventListeners() {
		this.listeners.sensing = []
	}

	trackProduct(product) {
		console.info('trackProduct: ' + product)
		if (
			this.websocketOpen &&
			this.hubSocket &&
			this.hubSocket.readyState === 1
		) {
			return this.hubSocket.send(
				JSON.stringify({
					type: 'track',
					product: product,
					button: 'none',
					actions: 'pickup'
				})
			)
		} else {
			console.error(
				new Error('Unable to trackProduct, Websocket not connected.')
			)
		}
	}

	trackButton(button, product) {
		console.info('trackButton: ' + button + '  (' + product + ')')
		if (button === 'Explore all products') {
			// pause videos for specific popups a hack
			var arrayLength = perchVideo.instances.length
			for (var i = 0; i < arrayLength; i++) {
				perchVideo.instances[i].obj.pause()
			}
		}

		if (
			this.websocketOpen &&
			this.hubSocket &&
			this.hubSocket.readyState === 1
		) {
			return this.hubSocket.send(
				JSON.stringify({
					type: 'track',
					product: product,
					button: button,
					actions: 'touch'
				})
			)
		} else {
			console.error(new Error('Unable to trackButton; Websocket not connected'))
		}
	}

	onSensingMessage(e) {
		try {
			var msg = JSON.parse(e.data)
			console.info(msg)
			this.emitPerchEvent(msg)
		} catch (err) {
			console.info('ERROR parsing message', e.data)
			console.error(err)
		}
	}

	emitPerchEvent(obj) {
		// eslint-disable-next-line no-unused-vars
		this.listeners.sensing.forEach((listener, l) => {
			listener(obj)
		})
		if (!this.listeners.sensing.length) {
			console.warn('[hub.js]: no sensing listeners, check if client.on("sensing", cb) is being called')
		}

	}
}

export const client = new PerchHubConnection()
