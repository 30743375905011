import { useStaticQuery, graphql } from 'gatsby'

const getSlideshowImages = () => {
	const { allFile: images } = useStaticQuery(
		graphql`
			query slideshowImages {
				allFile(filter: { sourceInstanceName: { eq: "slideshows" } }) {
					edges {
						node {
							relativeDirectory
							childImageSharp {
								gatsbyImageData(placeholder: NONE)
							}
						}
					}
				}
			}
		`
	)
	return images.edges
}

export default getSlideshowImages
