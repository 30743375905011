import React, { createContext, useEffect, useState, useCallback } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { useIdleTimer } from 'react-idle-timer'

import { getCollections } from 'hooks'
import { client } from 'utils/hub'
import { analyticsSend } from 'utils'
import { useEnv } from 'utils/env'

export const WebSocketsContext = createContext({
	collections: [],
	setCollections: () => {}
})
const handleOnIdle = () => navigate('/')

const timeoutDefault = process.env.GATSBY_TIMEOUT_MS
const defaultHubUrl = process.env.GATSBY_HUB_URL

export const WebSocketsProvider = ({ children, enableTimer, onSensing }) => {
	const collections = getCollections()
	const [collectionsList, setCollections] = useState(collections)
	const [listener, setListener] = useState(false)
	const timeoutMS = useEnv('GATSBY_TIMEOUT_MS', timeoutDefault)
	const hubUrl = useEnv('GATSBY_HUB_URL', defaultHubUrl)
	const { start, pause } = useIdleTimer({
		timeout: parseInt(timeoutMS ?? 60000),
		onIdle: handleOnIdle,
		startManually: true
	})
	useEffect(() => {
		client.setupSocket(hubUrl)
		return () => {
			client.removeAllEventListeners()
		}
	}, [hubUrl])
	useEffect(() => {
		setListener(true)
	}, [setListener])
	useEffect(() => {
		if (!listener) {
			window.addEventListener(
				'message',
				event => {
					if (
						event.data.source &&
						!event.data.source.includes('react-devtools')
					) {
						console.info('New event from iframe')
						console.info(event)
					}
				},
				false
			)
		}
	}, [listener])

	useEffect(() => {
		enableTimer ? start() : pause()
	}, [enableTimer, start, pause])

	const getRoute = useCallback(
		shelf => {
			const shelfNumber = shelf.name.replace('product_', '')
			return `/collection${
				collectionsList.includes(`/collection${shelfNumber}`) ? shelfNumber : 1
			}/`
		},
		[collectionsList]
	)
	const sendAnalytics = useCallback(shelfName => {
		analyticsSend({
			type: 'sensing',
			product: `Shelf #${shelfName.replace('product_', '')}`
		})
	}, [])
	const onMessage = useCallback(
		message => {
			const { data } = message
			if (!data) {
				console.warn('No data from websocket')
			}
			console.info('onMessage callback')
			const shelf = data.regions.find(shelf => shelf.state && shelf.statechange)
			if (shelf) {
				if (onSensing) {
					onSensing({
						route: getRoute(shelf),
						shelfName: shelf.name,
						sendAnalytics
					})
				} else {
					navigate(getRoute(shelf), { replace: true })
					sendAnalytics(shelf.name)
				}
			}
		},
		[sendAnalytics]
	)
	useEffect(() => {
		client.on('sensing', onMessage)
		return () => {
			client.removeAllEventListeners()
		}
	}, [onMessage])
	return (
		<WebSocketsContext.Provider
			value={{
				collections: collectionsList,
				setCollections
			}}
		>
			{children}
		</WebSocketsContext.Provider>
	)
}

WebSocketsProvider.propTypes = {
	children: PropTypes.node.isRequired,
	enableTimer: PropTypes.bool,
	onSensing: PropTypes.func
}

export const withWebSockets = Component => props => (
	<WebSocketsProvider>
		<Component {...props} />
	</WebSocketsProvider>
)
