import { useStaticQuery, graphql } from 'gatsby'

const getVideosData = () => {
	const { allFile: files } = useStaticQuery(
		graphql`
			query videosData {
				allFile(filter: { sourceInstanceName: { eq: "videos" } }) {
					edges {
						node {
							publicURL
							childrenImageSharp {
								fixed {
									src
								}
							}
						}
					}
				}
			}
		`
	)
	return files.edges
}

export default getVideosData
