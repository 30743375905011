export const extractData = dataObject =>
	dataObject?.edges.map(node => node.node)

export const formatVideoData = videoArray => {
	const posters = []
	const videos = []
	videoArray.forEach(video => {
		if (video.node.childrenImageSharp.length) {
			posters.push(video.node.childrenImageSharp[0].fixed.src)
		} else {
			videos.push(video.node.publicURL)
		}
	})
	return { posters, videos }
}

export const filterSlideshowImages = (
	imagesArray,
	shelf,
	product,
	inline = false
) =>
	imagesArray
		.filter(
			image =>
				image.node.relativeDirectory.includes(
					`${inline ? 'collection' : 'shelf'}${shelf}/`
				) && image.node.relativeDirectory.includes(`p${product}`)
		)
		.map(image => image.node.childImageSharp)
